import { useMutation } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import Loader from "components/Loader";
import { UPDATE_SALARY } from "../../api";
import { useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import t from "translate";
import { ISalary } from "interfaces/Salary";
import { removeTypeName } from "utils";
import { getOnlyUpdatedFields } from "helpers/update";
import NumberInput from "UI/NumberInput";

interface Props {
  open: boolean;
  toggleModalVisible: VoidFunction;
  refetch: any;
  salary?: ISalary;
}

function UpdateSalary({ open, toggleModalVisible, refetch, salary }: Props) {
  const [salaryType, setSalaryType] = useState<"fixed" | "percent">(
    salary?.type || "fixed"
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const [updateSalary, { data, loading }] = useMutation(UPDATE_SALARY);

  const handleCancel = () => {
    reset({});
    toggleModalVisible();
  };

  const handleFinish = ({ title, amount }: FieldValues) => {
    const { _id, ...initialValues } = removeTypeName(salary as any);
    let normalizedAmount = Math.floor(parseInt(amount));
    if (salaryType === "percent") {
      normalizedAmount =
        parseInt(amount) > 100
          ? 100
          : parseInt(amount) < 0
          ? 10
          : Math.floor(parseInt(amount));
    }

    const formValues = { title, amount: normalizedAmount, type: salaryType };

    const updatedFields = getOnlyUpdatedFields(initialValues, formValues);

    if (Object.values(updatedFields)?.length) {
      updateSalary({
        variables: {
          salaryId: salary?._id,
          data: updatedFields,
        },
      });
    }
  };

  useEffect(() => {
    if (salary) {
      reset(salary);
      setSalaryType(salary.type);
    }
  }, [salary]);

  useEffect(() => {
    if (!loading && data) {
      handleCancel();
      refetch();
      toast.success(t("Salary updated successfully!"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const activeCardStyle = {
    bgcolor: colors.blue[50],
    borderColor: colors.blue[400],
  };

  const isSalaryTypePercent = salaryType === "percent";

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{t("update salary")}</DialogTitle>
        <DialogContent>
          <Box gap={3} display="flex" flexDirection="column">
            <Box gap={2} display="flex">
              <Card
                sx={[
                  !isSalaryTypePercent ? activeCardStyle : {},
                  { width: "100%" },
                ]}
              >
                <CardActionArea onClick={() => setSalaryType("fixed")}>
                  <CardContent sx={{ py: 3 }}>
                    <Typography textAlign="center" variant="h5" mb={1}>
                      $
                    </Typography>
                    <Typography
                      textAlign="center"
                      color="GrayText"
                      variant="body1"
                    >
                      {t("Fixed")}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                sx={[
                  isSalaryTypePercent ? activeCardStyle : {},
                  { width: "100%" },
                ]}
              >
                <CardActionArea onClick={() => setSalaryType("percent")}>
                  <CardContent sx={{ py: 3 }}>
                    <Typography textAlign="center" variant="h5" mb={1}>
                      %
                    </Typography>
                    <Typography
                      textAlign="center"
                      color="GrayText"
                      variant="body1"
                    >
                      {t("Percent")}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
            <Box gap={2} display="flex" component="form" flexDirection="column">
              <Controller
                name="title"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        label={t("title")}
                        {...field}
                        error={!!errors[field.name]}
                        helperText={
                          !!errors[field.name] && t("Please input title!")
                        }
                      />
                    </FormControl>
                  );
                }}
              />
              <Controller
                name="amount"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <NumberInput
                        field={field}
                        label="amount"
                        errors={errors}
                        helperText="Please input amount!"
                        InputProps={{
                          endAdornment: isSalaryTypePercent ? (
                            <InputAdornment position="end">%</InputAdornment>
                          ) : (
                            <InputAdornment position="end">sum</InputAdornment>
                          ),
                          inputProps: isSalaryTypePercent
                            ? { max: 100, min: 10 }
                            : {},
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
              {isSalaryTypePercent && (
                <Alert severity="info">
                  {t("An employee's salary depended on his(her) group!")}
                </Alert>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleFinish)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateSalary;
