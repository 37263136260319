import { Pagination } from "@mui/material";
import { useAppDispatch } from "hooks/redux";
import React, { useCallback, useEffect, useMemo } from "react";
import { paginationActions } from "store/reducer/PaginationSlice";

type PaginationActionKeys = keyof typeof paginationActions;

type Props = {
  actionFuncName: PaginationActionKeys;
  page: number;
  totalPages: number;
};

function CustomPagination({ actionFuncName, page, totalPages }: Props) {
  const dispatch = useAppDispatch();

  const action = useMemo(
    () => paginationActions[actionFuncName],
    [actionFuncName]
  );

  const onPageChange = useCallback(
    (value: number) => {
      dispatch(action(value));
    },
    [action, dispatch]
  );

  useEffect(() => {
    if (page > totalPages) {
      dispatch(action(1));
    }
  }, []);

  return (
    <Pagination
      page={page}
      onChange={(_, page) => onPageChange(page)}
      count={totalPages}
    />
  );
}

export default CustomPagination;
