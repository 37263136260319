import React, { useCallback, useEffect, useMemo } from "react";

import {
  TextField,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
} from "@mui/material";
import t from "translate";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import paths from "appConstants/RoutePaths";
import { IStudent } from "interfaces/Groups";
import { addStudent } from "store/reducer/CenterSlice";
import { GET_APPLY_REQUESTS_TO_SEARCH, GET_STUDENTS } from "./api";

import {
  GroupHeader,
  GroupItems,
  StyledAutocomplete,
} from "./StudentSearchInput.style";
import { ApplyRequestType } from "pages/Center/Admission/types";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

interface StudentType extends IStudent {
  __typename: string;
}

interface ApplyRequestWithTypename extends ApplyRequestType {
  __typename: string;
}

type SelectedValue = StudentType | ApplyRequestWithTypename;

function StudentSearchInput() {
  const centerId = useAppSelector((store) => store.center.center?._id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    getStudentsForSearch,
    { data: studentsData, loading: studentsLoading },
  ] = useLazyQuery(GET_STUDENTS, {
    variables: {
      center: centerId,
    },
  });

  const [getApplyRequestsForSearch, { data, loading }] = useLazyQuery(
    GET_APPLY_REQUESTS_TO_SEARCH,
    {
      variables: {
        center: centerId,
      },
    }
  );

  const students = studentsData?.getStudentsForSearch?.payload || [];
  const applyRequests: ApplyRequestWithTypename[] = useMemo(
    () => data?.getAllApplyRequestsForSearch?.payload || [],
    [data]
  );

  const handleSelectOption = useCallback(
    (selectedOption: any) => {
      if (selectedOption && selectedOption?.__typename === "Student") {
        dispatch(addStudent(selectedOption));
        navigate(paths.CENTER_STUDENT_INFO);
        return;
      }

      if (selectedOption && selectedOption?.__typename === "ApplyRequest") {
        const foundIndex = applyRequests.findIndex(
          (elem: ApplyRequestType) => elem._id === selectedOption._id
        );

        const page = Math.floor(foundIndex / 10 + 1);
        const queryParams = `?tab=${selectedOption.status}&page=${page}&applyId=${selectedOption._id}&date=${selectedOption.createdAt}`;

        navigate(paths.CENTER_ADMISSION + queryParams);
      }
    },
    [applyRequests, dispatch, navigate]
  );

  const groupBy = useCallback((option: any) => {
    if (option?.__typename === "ApplyRequest") {
      return t("admission") + ": " + option?.status;
    }
    return t("students");
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: <SearchRoundedIcon color="disabled" />,
        }}
        variant="outlined"
        placeholder={t("Search student...")}
        sx={{ borderRadius: 2 }}
      />
    ),
    []
  );

  const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => {
    return (
      <li key={params.key}>
        <GroupHeader>{params.group}</GroupHeader>
        <GroupItems>{params.children}</GroupItems>
      </li>
    );
  }, []);

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
      return (
        <li {...props} key={option._id}>
          {option?.name || option?.user?.name}
        </li>
      );
    },
    []
  );

  useEffect(() => {
    if (centerId) {
      getStudentsForSearch();
      getApplyRequestsForSearch();
    }
  }, [centerId]);

  if (!centerId) {
    return <></>;
  }

  return (
    <StyledAutocomplete
      clearOnBlur
      groupBy={groupBy}
      renderGroup={renderGroup}
      renderInput={renderInput}
      renderOption={renderOption}
      loading={loading || studentsLoading}
      options={students.concat(applyRequests)}
      getOptionLabel={(option: any) => option.name}
      sx={{ display: { xs: "none", sm: "block" } }}
      onChange={(_, newValue) => handleSelectOption(newValue as SelectedValue)}
    />
  );
}

export default StudentSearchInput;
