import styled from "@emotion/styled";
import { Autocomplete, colors, lighten } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: 250,

  ".MuiOutlinedInput-root": {
    padding: "0 8px !important",
  },

  ".MuiAutocomplete-endAdornment": {
    display: "none",
  },

  ".MuiOutlinedInput-notchedOutline": {
    borderRadius: 8,
  },
}));

export const GroupHeader = styled("div")(() => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: colors.blue[800],
  backgroundColor: lighten(colors.blue[200], 0.85),
  fontSize: 14,
}));

export const GroupItems = styled("ul")({
  padding: 0,
});
