function removeAllLinksAndTagsFromString(value: any): unknown {
  if (
    value === null ||
    (typeof value !== "object" && typeof value !== "string")
  ) {
    return value;
  }

  if (typeof value === "string") {
    const noTags = value.replace(/<\/?[^>]+(>|$)/g, "");
    return noTags;
  }

  if (Array.isArray(value)) {
    return value.map(removeAllLinksAndTagsFromString);
  }

  if (value?._isAMomentObject && value?._isValid) {
    return value?.format();
  } else if (value?._isAMomentObject && !value?._isValid) {
    return;
  }

  if (value instanceof Date) {
    return value.toString();
  }

  const entriesValue = Object.entries(value);

  const obj = entriesValue.reduce(
    (acc: { [key: string]: any }, [key, value]) => {
      acc[key] = removeAllLinksAndTagsFromString(value);

      return acc;
    },
    {}
  );
  return obj;
}

export default removeAllLinksAndTagsFromString;
