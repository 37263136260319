import StyleLoader from "./Style";
import { CircularProgress } from "@mui/material";

function Loader() {
  return (
    <StyleLoader>
      <CircularProgress />
    </StyleLoader>
  );
}

export default Loader;
