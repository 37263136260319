import { gql } from "@apollo/client";

export const GET_STUDENTS = gql`
  query getStudentsForSearch($center: ID!) {
    getStudentsForSearch(center: $center) {
      payload {
        _id
        name
        phone
        extraPhone
        educationLevel
        gradeLevel
        schoolNumber
        dateOfBirthday
        image
      }
    }
  }
`;

export const GET_APPLY_REQUESTS_TO_SEARCH = gql`
  query getAllApplyRequestsForSearch($center: ID!) {
    getAllApplyRequestsForSearch(center: $center) {
      payload {
        _id
        name
        createdAt
        user {
          name
        }
        status
      }
    }
  }
`;
